import type { UserRole } from '@repo/db/schema'
import type { PropsWithChildren } from 'react'
import { useAuthenticatedUser } from '~/hooks/useAuthenticatedUser'

export const RoleGuard = ({
  roles,
  children
}: PropsWithChildren<{ roles: UserRole[] }>) => {
  const user = useAuthenticatedUser()

  if (user.isInternal) return children

  if (!user.role || !roles.includes(user.role)) return null

  return children
}
